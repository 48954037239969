/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Cny = {
		// All pages
		'common': {
			sideBarActiveState: function() {
			var $pageTitle = $('.page-title').text();
				switch ($pageTitle) {
					case 'Video Store Ordering Info':
						$('#1002_ordering-info').addClass('active');
						break;
					case 'Non Theatrical Ordering Info':
						$('#1006_ordering-info').addClass('active');
						break;
					case 'Non Theatrical and Educational Booking':
						$('#1006_new-releases').addClass('active');
				}
			},
			init: function() {
				// JavaScript to be fired on all pages
				window.cny.submitForm();
				Cny.common.sideBarActiveState();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'product_detail_page': {
			init: function() {
				// JavaScript to be fired on the about us page
				$.tablesorter.addParser({
					// set a unique id
					id: 'playdate',
					is: function (s) {
						// return false so this parser is not auto detected
						return false;
					},
					format: function (s) {
						// format your data for normalization
						return s;
					},
					// set type, either numeric or text
					type: 'numeric'
				});
				var playdateExtraction = function (node) {
					if ($(node).children('span.hidden').length > 0) {
						// extract data from markup and return it
						return $(node).children('span.hidden').html();
					} else if ($(node).children('a').length > 0) {
						return $(node).children('a').html();
					} else {
						return node.innerHTML;
					}
				};
				/* Playdates sort capability; no sorting by date */
				$(".playdates").tablesorter({
					textExtraction: playdateExtraction,
					headers: {
						3: {
							sorter: 'playdate'
						}
					}
				});
				/* enable sorting links */
				$("#playdates .trigger-link").click(function () {
					var new_order = 0;
					var sort_order = $(this).attr("data-sort-order");
					var column = $(this).attr("data-sort-column");

					if (sort_order != 1) new_order = 1;
					$(this).attr("data-sort-order", new_order);

					var sorting = [[column, new_order]];
					$(".playdates").trigger("sorton", [sorting]);

					return false;
				});
				/* handle past/future/all with AJAX call*/
				$("#playdates .ajax-load").click(function () {
					$.get("/film/playdates/film_id/" + $(this).attr("data-film_id") + "/list/" + $(this).attr("data-view"), function (html) {
						$("table.playdates tbody").html(html);

						$(".playdates").trigger("update");
					});
					return false;
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Cny;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
